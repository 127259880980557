import React, { useState, useEffect } from "react";
import { Modal, Button, Checkbox, FormControlLabel } from "@mui/material";

const icalURL =
  "https://calendar.google.com/calendar/ical/[calId]/public/basic.ics";
const addToCalUrl =
  "https://calendar.google.com/calendar/u/0/r?cid=[calId]&cid=[calId]....";
const directPublicUrl =
  "https://calendar.google.com/calendar/embed?src=[calId]&ctz=America%2FNew_York";

const PublicCalendarPage = () => {
  const origCalList = [
    {
      shortId: "lsdf8sd",
      name: "בית רבינו",
      id: "YWY5NDFmZTI5ODE5YjFiNDE1OGQ0ZWRkOWQ1OGVjMzhlZGYyZjUzMzlmNGM3NDhlODRjNTlhODVlOGUxZTQ1YUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t",
      include: true,
      color: "abd3d3",
      calId:
        "af941fe29819b1b4158d4edd9d58ec38edf2f5339f4c748e84c59a85e8e1e45a@group.calendar.google.com",
    },
    {
      shortId: "lkj765w",
      name: "שמחת אנש בשיכון",
      id: "ZDNiNmU0OTAyZDEyNzliZWQ0NGNjY2VjYWRiZTQ0ODQ4NTAyMTY1YWYxN2JlYWI2NThjOWEzYjg1MjY3MmZkZUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t",
      include: true,
      color: "DAC1DA",
      calId:
        "d3b6e4902d1279bed44cccecadbe44848502165af17beab658c9a3b852672fde@group.calendar.google.com",
    },
    {
      shortId: "poi98yu",
      name: "שמחת אנש ברחבי תבל",
      id: "YTMxMjc1YmEzMTEwMWUzOTMxOWZiMDc1ZjA5MmZmMjY3OTY0NjY4NTA5ZTM0NjBlNzRmNjU1NDJkMmUzMzg0Y0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t",
      include: true,
      color: "FFB9A0",
      calId:
        "a31275ba31101e39319fb075f092ff267964668509e3460e74f65542d2e3384c@group.calendar.google.com",
    },
    {
      shortId: "jkh34fg",
      name: "מסיבות ציבורי",
      id: "OGE5MzU4Y2NlZjYxODRkMzg2MDA1MDAxNzkzZDIwMDBhM2QzNWU5NDk5MDQ4NWE4NGEwYmU0Njk2ODQzMTM0YUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t",
      include: true,
      color: "A366A3",
      calId:
        "8a9358ccef6184d386005001793d2000a3d35e94990485a84a0be4696843134a@group.calendar.google.com",
    },
    {
      shortId: "mnb123v",
      name: "אידישע טעג און פרשיות (2025)",
      id: "cDJqZmxocmdsbW9iNnBscTA3c2NzYTY3NTFudG0yM2JAaW1wb3J0LmNhbGVuZGFyLmdvb2dsZS5jb20",
      include: true,
      color: "D1D1D1",
      calId: "p2jflhrglmob6plq07scsa6751ntm23b@import.calendar.google.com",
    },
  ];
  const [calList, setCalList] = useState(origCalList);

  useEffect(() => {
    const savedSettings =
      JSON.parse(localStorage.getItem("calendarSettings")) || {};
    const updatedList = origCalList.map((cal) => ({
      ...cal,
      include:
        savedSettings[cal.shortId] !== undefined
          ? savedSettings[cal.shortId]
          : cal.include,
    }));
    setCalList(updatedList);
  }, []);

  const handleCheckboxChange = (shortId) => {
    const updatedList = calList.map((cal) =>
      cal.shortId === shortId ? { ...cal, include: !cal.include } : cal
    );
    setCalList(updatedList);

    // Save the `include` state to localStorage
    const updatedSettings = updatedList.reduce((acc, cal) => {
      acc[cal.shortId] = cal.include;
      return acc;
    }, {});
    localStorage.setItem("calendarSettings", JSON.stringify(updatedSettings));
  };

  return (
    <div style={{ margin: "0 auto", width: "90%", textAlign: "center" }}>
      <h1>יום סקווירא</h1>
      <h4 dir="rtl">
        צוצולייגן א חתונה אדער א מאורע שיקט צו{" "}
        <a
          target="_blank"
          href="mailto:yomskver@gmail.com?subject=New Event from Website&body=Event Type:%0D%0AEvent Description:%0D%0ADate:%0D%0ATime:%0D%0ALocation:%0D%0A"
        >
          yomskver@gmail.com
        </a>{" "}
        מיט אלע דעטאלן. אדער רופט{" "}
        <a href="#" target="_blank">
          845-293-5542
        </a>
      </h4>
      <h5 style={{ marginBottom: "2px" }}>
        זיך איינצושרייבן צו זעהן אלע מאורעות אין אייער אייגענע גוגל קאלענדער
        דריקט אויפן לינק אונטן אויף די לינקע זייט
      </h5>
      <div style={{ margin: "5px 0", direction: "rtl", fontSize: "12px" }}>
        {/* <h3>Select Calendars to Display</h3> */}
        {calList.map((cal) => (
          <FormControlLabel
            key={cal.shortId}
            control={
              <Checkbox
                checked={cal.include}
                onChange={() => handleCheckboxChange(cal.shortId)}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 15 } }}
              />
            }
            label={cal.name}
          />
        ))}
      </div>
      <iframe
        className="embededCal"
        src={`https://calendar.google.com/calendar/embed?height=350&wkst=1&ctz=America%2FNew_York&bgcolor=%23ffffff&showTitle=0&mode=WEEK&showCalendars=0${calList
          .filter((m) => m.include)
          .map((m) => `&src=${m.id}&color=%23${m.color}`)
          .join("")}`}
        style={{ borderWidth: 0 }}
        width={"100%"}
        height={"750"}
      />
    </div>
  );
};

export default PublicCalendarPage;
